import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "Press Release",
    props: ["post"],
    setup() {
        const router = useRouter();

        const goToPost = (id) => {
            router.push(`/press-releases/${id}`);
        };

        return { goToPost };
    },
});