<template>
  <div class="press-release" @click="goToPost(post.id)">
    <div class="h-36 md:h-52 2xl:h-72">
      <img :src="post.image" />
    </div>
    <div class="flex flex-col justify-center space-y-4 2xl:space-y-8">
      <div>
        <h2
          class="text-xl text-blue 2xl:text-4xl mb-2 font-semibold"
          style="color: #174890"
        >
          {{ post.title }}
        </h2>
        <p>
          {{ post.created_at }}
        </p>
      </div>
    </div>
  </div>
</template>
<style src="./press-release.css" scoped lang="css"></style>
<script src="./press-release.js"></script>
