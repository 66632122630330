<template>
  <div class="investment-opportunity md:h-48">
    <div class="h-36 md:h-full md:w-4/12">
      <img :src="image" />
    </div>
    <div
      class="md:w-8/12 md:h-full"
      :class="{'apple-green-light-bg': lightBg, 'apple-green-bg': !lightBg}"
    >
      <div class="card">
        <h3 class="text-xl 2xl:text-4xl font-semibold">
          <slot name="header"></slot>
        </h3>
        <p>
          <slot name="body"></slot>
        </p>
        <AnchorLink text="Read More" :url="link" />
      </div>
    </div>
  </div>
</template>

<style src="./investment-opportunity-card.component.css" scoped lang="css"></style>
<script src="./investment-opportunity-card.component.js"></script>