import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";

import Hero from "@/components/hero/index.vue";
import AnchorLink from "@/components/anchor-link/index.vue";
import PressRelease from "@/components/press-release/index.vue";
import InvestmentOpportunityCard from "@/components/investment-opportunity-card/index.vue";

import agriculture from "../../assets/images/agriculture.png";
import mining from "../../assets/images/mining.png";
import transportation from "../../assets/images/transportation.png";
import infrastructure from "../../assets/images/infrastructure.png";
import healthcare from "../../assets/images/healthcare.png";
import energy from "../../assets/images/energy.png";
import heroBg from "../../assets/images/test-bg.png";
import heroBg1 from "../../assets/images/test-bg1.png";
import heroBg3 from "../../assets/images/main-3-bg.png";

export default defineComponent({
  name: "Home",
  components: { Hero, PressRelease, InvestmentOpportunityCard, AnchorLink },
  setup() {
    const store = useStore();
    const heroSlides = [
      {
        title: "Nasarawa State",
        subTitle: "Nigeria's New Investment Frontier",
        image: heroBg,
        link: "why-nasarawa",
      },
      {
        title: "Nasarawa State",
        subTitle: "Boosting Private Sector Participation",
        image: heroBg1,
        link: "investment-opportunities",
      },
      {
        title: "Nasarawa State",
        subTitle: "Innovatively Diversifying Our Economy",
        image: heroBg3,
        link: "investment-opportunities",
      },
    ];

    onMounted(() => {
      store.dispatch("getAllCorporations");
      store.dispatch("getAllPressReleases", 1);
    });

    const loading = computed(() => store.state.loadingModule.loading);
    const pressReleases = computed(
      () => store.state.pressReleasesModule.pressReleases
    );
    const corporations = computed(
      () => store.state.corporationsModule.corporations
    );

    const options = {
      perPage: 8,
      gap: "2rem",
      type: "loop",
      arrows: false,
      pagination: false,
      autoplay: true,
    };

    const mobileOptions = {
      perPage: 3,
      gap: "1rem",
      type: "loop",
      arrows: false,
      pagination: false,
      autoplay: true,
    };

    return {
      loading,
      corporations,
      pressReleases,
      images: [agriculture, mining, transportation, healthcare, energy, infrastructure],
      options,
      mobileOptions,
      heroSlides,
    };
  },
});
