import { defineComponent } from "vue";
import AnchorLink from "../anchor-link/index.vue";

export default defineComponent({
  name: "Investment Opportunity Card",
  components: { AnchorLink },
  props: {
    image: {
      type: String,
      default: "learn more",
    },
    lightBg: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "#",
    },
  },
});
